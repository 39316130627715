<template>
  <div>
    <FaqEdit
      :item="currentItem"
      :update="updateItem"
      :close="closeEdit"
      :descSingular="descricaoSingularModulo('ADMIN_FAQ')"
      :getDescricaoAcessoSistema="getDescricaoAcessoSistema"
    ></FaqEdit>
    <div>
      <div class="content">
        <div class="content-wrapper">
          <Loading :isActive="loading"></Loading>
          <CTabs variant="tabs" class="nav-tabs-boxed">
            <CTab :title="descricaoPluralModulo('ADMIN_FAQ')">
              <div class="filters row" style="margin-top:0">
                <div class="col-1-1">
                  <div class="pull-right">
                    <button v-on:click="openCreate('faqEdit')" class="button button-success button-block">
                      <fa-icon icon="plus" />
                      Criar
                    </button>
                  </div>
                </div>
              </div>
              <div class="table-responsive">
                <table class="table">
                  <thead>
                    <tr>
                      <th>#</th>
                      <th>Título</th>
                      <th>Tipo</th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(item, index) in items" :key="index">
                      <td>{{ index + 1 }}</td>
                      <td>{{ item.titulo }}</td>
                      <td>{{ item.tipo }}</td>
                      <td class="actions">
                        <button type="button" class="button button-primary" v-on:click="openEdit(item, 'faqEdit')"><fa-icon icon="pencil-alt" /></button>
                        <button type="button" name="button" v-on:click="deleteConfirm(item.id)" class="button button-error"><fa-icon icon="trash-alt" /></button>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </CTab>
          </CTabs>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import clone from 'just-clone';
import { get, post, put, del } from '@/helpers/apiRequest';
import FaqEdit from './components/FaqEdit.vue';
import { descricaoPluralModulo, descricaoSingularModulo, getDescricaoAcessoSistema } from '@/helpers/cargas';

const Faq = {
  id                : null,
  titulo            : null,
  tipo              : 'artigo',
  palavras_chave    : [],
  conteudo          : null,  
  acessab2b         : 0,
  acessacallcenter  : 0,
  acessafv          : 0,
}

export default {
  name: 'faq',
  components: {
    FaqEdit,
  },
  data() {
    return {      
      loading: false,
      items: [],
      currentItem: {},
    };
  },
  methods: {
    descricaoPluralModulo,
    descricaoSingularModulo,
    getDescricaoAcessoSistema,
    loadFaq() {
      this.loading = true;
      get('/admin/faq')
        .then((json) => {
          this.items = json;
          this.loading = false;
        })
        .catch(() => (this.loading = false));
    },
    openCreate(modalName) {
      this.currentItem  = clone(Faq)

      this.$modal.show(modalName);
    },
    openEdit(item, modalName) {
      this.currentItem = clone(item);
      this.loading     = false;
      
      this.$modal.show(modalName);
    },
    closeEdit() {
      this.$modal.hide('faqEdit');
    },
    doRequest() {      
      return this.currentItem.id ?
      put(`/admin/faq/${this.currentItem.id}`, this.currentItem) :
      post('/admin/faq', this.currentItem);
    },
    updateItem() {
      this.doRequest()
        .then((json) => {
          this.closeEdit();
          this.loadFaq();
          this.$swal({
            title: 'Salvo com sucesso',
            text: json.message,
            showCancelButton: false,
            cancelButtonText: 'Fechar',
            showConfirmButton: true,
            icon: 'success',
          });          
        })
        .catch((error) => {
          this.$swal({
            title: 'Falha ao Salvar',
            text: error.message,
            showCancelButton: true,
            cancelButtonText: 'Fechar',
            showConfirmButton: false,
            icon: 'error',
          });
        });
    },
    deleteConfirm(id) {
      this.$swal({
        title: 'Confirma Remover ?',
        text: 'Essa ação não poderá ser desfeita',
        showCancelButton: true,
        icon: 'warning',
        confirmButtonText: 'Excluir',
        cancelButtonText: 'Cancelar',
      })
        .then((res) => {
          if (res.value) {
            this.loading = true;
            del(`/admin/faq/${id}`)
              .then((json) => {
                this.loading = false;
                this.$swal({
                  title: json.message,
                  icon: 'success',
                });
                this.loadFaq();
              })
              .catch((err) => {
                this.loading = false;
                this.$swal({
                  title: err.message,
                  icon: 'error',
                });
              });
          }
        })
        .catch(() => {});
    },    
  },
  beforeMount() {
    this.loading = true;
    this.loadFaq();
  },
};
</script>
